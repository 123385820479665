<template>
  <div class="bg-white p-20 rounded">
    <div class="mb-40" v-for="(item, index) in computedData" :key="item.ID">
      <div class="mb-10">
        <span>第{{ index + 1 }}题：{{ item.Title }}</span>
        <a-select style="width: 200px" v-model="type" v-if="index === 0">
          <a-select-option
            :value="option.ID"
            v-for="option in item.Options"
            :key="option.ID"
            >{{ option.Name }}</a-select-option
          >
        </a-select>
      </div>
      <a-table
        :scroll="{ y: 300 }"
        :pagination="false"
        :dataSource="item.Options"
        :columns="columns"
      >
        <a-progress
          status="normal"
          :percent="text"
          slot="progress"
          slot-scope="text"
          v-if="text !== -1"
          :showInfo="false"
        ></a-progress>

        <div slot-scope="text" slot="count">
          {{ text || 0 }}
        </div>

        <template slot="footer" slot-scope="currentPageData">
          <div>
            <span class="mr-40">本题有效填写人数</span>
            <span>{{ item.total }}</span>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { getQuestion } from "@/api1/common";
export default {
  data() {
    return {
      columns: [
        {
          dataIndex: "Name",
          title: "选项",
        },
        {
          dataIndex: "Count",
          title: "小计",
          scopedSlots: { customRender: "count" },
          width: 100,
        },
        {
          dataIndex: "progress",
          title: "比例",
          scopedSlots: { customRender: "progress" },
          width: 200,
        },
      ],
      data: [],
      type: 1,
    };
  },
  computed: {
    computedData() {
      if (this.type === 1) {
        return [
          this.data[0],
          this.data[1],
          this.data[2],
          this.data[3],
          this.data[4],
        ];
      } else {
        return [
          this.data[0],
          this.data[5],
          this.data[6],
          this.data[7],
          this.data[8],
        ];
      }
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getQuestion(this.id);
  },
  methods: {
    getQuestion(id) {
      getQuestion(id).then(({ data }) => {
        const items = data.Stats.Items;
        const path = [1];
        this.data = data.Detail.Items.map((item) => {
          const stats = items.find((data) => data.ID === item.ID) || {};
          const options = stats.Options || [];
          const temp = { ...item };
          const total = stats.Count || 0;
          temp.Options = temp.Options.map((option) => {
            const tem = Object.assign(
              option,
              options.find((op) => op.ID === option.ID)
            );
            const pro = ((+tem.Count / total) * 100).toFixed(2);
            tem.progress = isNaN(pro) ? 0 : parseFloat(pro);
            return tem;
          });
          temp.total = total;
          return temp;
        });
      });
    },
  },
};
</script>
